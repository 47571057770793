export const ROUTES = {
  HOME: '',
  DEVICE_DETAILS: 'device-details/:uuid',
  OFFLINE_MANAGEMENT: 'device-details/:uuid/offline-management',
  OVERVIEW_HISTORY: 'device-details/:uuid/history',
  HISTORY_DETAILS: 'device-details/:uuid/history/:historyId',
  ADMIN_PANEL: 'license-availability',
  USER_PERMISSIONS: 'license-availability/user-permissions',
  LICENSE_AVAILABILITY: 'license-availability/license-availability',
  HELP_CENTER: 'help-center',
  ERROR: {
    ERROR: 'error',
    NOT_FOUND: 'not-found',
    NOT_AUTHORIZED: 'not-authorized',
    SERVER_ERROR: 'server-error',
    SESSION_EXPIRED: 'session-expired',
    FORBIDDEN: 'forbidden'
  },
  REDIRECT_URI: 'auth/callback',
  LOGIN: 'login',
  SILENT_REFRESH: 'silent-refresh',
  MANAGE_PERMISSIONS: 'manage-permissions',
  MANAGE_LICENSE_AVAILABILITY: 'admin-panel/license-availability/:uuid'
};
